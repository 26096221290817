import { isFieldComplete } from './utils/formUtils';

const FinancingSection = ({ handleProgress }) => {
  const handleFinancingChange = (e) => {
    const selectedOption = e.target.value;
    const isCompleted = isFieldComplete(selectedOption); // Using the isFieldComplete function
    handleProgress('financing', isCompleted, selectedOption);
  };

  const financingOptions = [
    "", // For the default "Select an option" value
    "Cash Buyer",
    "Pre-Approved",
    "Explore Financing Options",
  ];

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">How can I help with financing?</div>
      <div className="mb-5">
        <select onChange={handleFinancingChange}>
          {financingOptions.map((option, index) => (
            <option key={index} value={option}>
              {option || "Select an option"}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FinancingSection;
