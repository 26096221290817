import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from './ProgressBar';
import TimeFrameSection from './TimeFrameSection';
import NeighborhoodSection from './NeighborhoodSection';
import HomeStyleSection from './HomeStyleSection';
import FinancingSection from './FinancingSection';
import BudgetSection from './BudgetSection';
import SellingProperty from './SellingProperty';
import ContactDetailsSection from './ContactDetailsSection';
import SchedulingAppointmentSection from './SchedulingAppointmentSection';
import InvestingOptionsSection from './InvestingOptionsSection';
import { calculateProgress } from './utils/formUtils';
import StoreLead from '../../API/StoreLead';

import './LeadGenerationForm.css';

const initialFields = {
  timeframe: { isComplete: false, value: undefined },
  neighborhood: { isComplete: false, value: undefined },
  homeStyle: { isComplete: false, value: "" },
  financing: { isComplete: false, value: undefined },
  budget: { isComplete: false, value: undefined },
  sellingProperty: { isComplete: false, value: undefined },
  contactDetails: { isComplete: false, value: undefined },
  scheduling: { isComplete: false, value: undefined },
  investing: { isComplete: false, value: undefined },
};

const LeadGenerationForm = ({ userInterest, broker, nbhood1, nbhood2, nbhood3, nbhood4 = 'Other'}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [fields, setFields] = useState(initialFields);

  // Reset state when userInterest changes
  useEffect(() => {
    setFields(initialFields);
    setSectionIndex(0);
    setProgress(0);
    scrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }, [userInterest]);

  const paths = {
    Buying: [
      {
        component: NeighborhoodSection,
        fieldKey: 'neighborhood',
        params: {nbhood1, nbhood2, nbhood3, nbhood4},
      },
      { component: BudgetSection, fieldKey: 'budget' },
      { component: TimeFrameSection, fieldKey: 'timeframe' },
      { component: HomeStyleSection, fieldKey: 'homeStyle'},
      { component: FinancingSection, fieldKey: 'financing' },
    ],
    Selling: [
      { component: SchedulingAppointmentSection, fieldKey: 'scheduling' },
      { component: TimeFrameSection, fieldKey: 'timeframe' },
    ],
    Investing: [
      { component: InvestingOptionsSection, fieldKey: 'investing'},
      { component: BudgetSection, fieldKey: 'budget' },
      { component: FinancingSection, fieldKey: 'financing' },
      { component: TimeFrameSection, fieldKey: 'timeframe' },
    ],
    Relocating: [
      { component: BudgetSection, fieldKey: 'budget' },
      { component: SellingProperty, fieldKey: 'sellingProperty'},
      { component: TimeFrameSection, fieldKey: 'timeframe' },
    ]
  };

  const [sectionIndex, setSectionIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  // Function to build props for CurrentSection
  const getCurrentSectionProps = () => {
    const baseProps = {
      handleProgress: handleProgress,
      value: fields[currentFieldKey]?.value || '',
    };

    // Conditionally add more props based on the component's requirements
    if (CurrentSection === NeighborhoodSection) {
      return {
        ...baseProps,
        neighborhood1: nbhood1,
        neighborhood2: nbhood2,
        neighborhood3: nbhood3,
        neighborhood4: nbhood4
      };
    }

    // Add more conditions here for other sections if needed

    return baseProps;
  };

  const handleProgress = (fieldKey, isComplete, value) => {
    const updatedFields = {
      ...fields,
      [fieldKey]: { isComplete, value },
    };
  
    setFields(updatedFields);
  };

  const handleNext = () => {
  
    if (sectionIndex < (SECTIONS.length - 1)) {
      // Check if fields[currentFieldKey] exists and if its isComplete property is true
      if (fields[currentFieldKey]?.isComplete) {
        const newProgress = calculateProgress(fields, SECTIONS.length);
        setProgress(newProgress);
        setSectionIndex(sectionIndex + 1);
      } else {
        if (fields[currentFieldKey]?.isComplete) {
          handleSubmit();
        } else {
          console.log("You have not completed this step");
        }
      }
    }
  };

  const currentPath = userInterest

  const handleSubmit = async (e) => {
    setProgress(100);
    e.preventDefault();

    // Transform the fields state into the structure expected by the backend
    const leadData = {
      broker: broker,
      userInterest,
      timeframe: fields.timeframe.value,
      neighborhood: fields.neighborhood.value,
      homeStyle: fields.homeStyle.value,
      sellingProperty: fields.sellingProperty.value,
      financing: fields.financing.value,
      budget: fields.budget.value,
      contactDetails: fields.contactDetails.value,
      scheduling: fields.scheduling.value,
      investing: fields.investing.value,
    };

    try {
      const response = await StoreLead(leadData);
      console.log("Lead stored:", response);
    } catch (error) {
      console.error("Error storing lead", error);
    }

    setIsSubmitted(true);
  };

  const scrollToRef = useRef(null);
  // Scroll to the referenced element in useEffect
  useEffect(() => {
    scrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }, []);

  const SECTIONS = [
    ...(paths[currentPath] || []),
    { component: ContactDetailsSection, fieldKey: 'contactDetails' },
  ];

  const CurrentSection = SECTIONS[sectionIndex]?.component;
  const currentFieldKey = SECTIONS[sectionIndex]?.fieldKey;

  if (isSubmitted) {
    return (
      <div className="thank-you-container justify-content-center text-center m-5">
        <div className="thank-you-box p-3 p-md-5 m-1">
          <h1>Thank you! You'll hear from me soon!</h1>
        </div>
      </div>
    );
  }

  return (
    <div ref={scrollToRef} className="form-container mt-3">
      <form className='form p-5 justify-content-center text-center mb-4 mt-4' onSubmit={handleSubmit}>
        <ProgressBar progress={progress} />
        {
          CurrentSection ? 
          <CurrentSection {...getCurrentSectionProps()} /> : null
        }
        {
          sectionIndex < (SECTIONS.length - 1) ? (
            <button className="lead-gen-next-button" type="button" onClick={() => handleNext()}>Next</button>
          ) : (
            <div></div>
          )
        }
      </form>
    </div>
  );
};

export default LeadGenerationForm;
