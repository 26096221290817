import axios from 'axios';

const StoreLead = async (lead) => {
  try {
    const response = await axios.post('/addLead', lead);
    console.log('Lead stored');
    return response.data;
  } catch (error) {
    console.error('Error storing lead', error);
    throw error;
  }
};

export default StoreLead;
