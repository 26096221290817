import { isFieldComplete } from './utils/formUtils';

const HomeStyleSection = ({ handleProgress }) => {
  const handleHomeStyleBlur = (e) => {
    const homeStyle = e.target.value.trim();
    const isCompleted = isFieldComplete(homeStyle);
    handleProgress('homeStyle', isCompleted, homeStyle);
  };

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">What style of home do you prefer? This can be brief or descriptive</div>
      <input className="mb-5" type="text" name="homeStyle" onBlur={handleHomeStyleBlur} />
    </div>
  );
};

export default HomeStyleSection;
