import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation } from 'swiper/core';

import 'swiper/css';
import 'swiper/css/navigation';

import './EpisodeCarouselSection.css';

import thumbnail1 from '../../../images/thumbnails/30athumbnail.png'
import thumbnail2 from '../../../images/thumbnails/bridgetthumbnail.png'
import thumbnail3 from '../../../images/thumbnails/p2crewthumbnail.png'
import thumbnail4 from '../../../images/thumbnails/cleelumthumbnail.png'

import SocialMediaIcons from '../HeroSection/SocialMediaIcons';

// Enable required module
SwiperCore.use([Navigation]);

const episodes = [
  {
    image: thumbnail1,
    videoUrl: "https://youtu.be/vdFqOJ0gZfs",
    name: "Debbie James",
    yt: "https://www.youtube.com/@debbiejamesrealtor",
    fb: "https://www.facebook.com/DebbieJames30A",
    insta: "https://www.instagram.com/debbiejames30a/",
    page: "/DebbieJames"
  },
  {
    image: thumbnail4,
    videoUrl: "https://www.youtube.com/watch?v=a5D6GDV76Sk",
    name: "Rachael Ann",
    yt: "https://www.youtube.com/@themasterplantv",
    fb: "https://www.facebook.com/themasterplan.tv/",
    insta: "https://www.instagram.com/themasterplan.tv/",
    page: "/DanRedwine"
  },
  {
    image: thumbnail2,
    videoUrl: "https://www.youtube.com/watch?v=ehv30-ADuwo",
    name: "Bridget Ramey",
    yt: "https://www.youtube.com/@Bridget-Ramey",
    fb: "https://www.facebook.com/BridgetRameyRealtor/",
    insta: "https://www.instagram.com/bridgetramey/",
    page: "/BridgetRamey"
  },
  {
    image: thumbnail3,
    videoUrl: "https://www.youtube.com/watch?v=YsMY3qAwAiQ",
    name: "P2 Crew",
    yt: "https://www.youtube.com/@P2Crew",
    fb: "https://www.facebook.com/TheP2Crew",
    insta: "https://www.instagram.com/thep2crew/",
    page: "/P2Crew"
  }
];

const EpisodeCarouselSection = () => {  
    return (
      <div className="featured-episodes-wrapper">
        <div className="featured-episodes-container mt-5 mb-5">
          <div className="row ml-0 mr-0">
            <div className="featured-episodes-title-text">
              <div className="col-12 text-center featured-episodes-section-title">A Real Estate + Lifestyle Adventure Show</div>
              <div className="col-12 mt-4 mb-4 text-center featured-episodes-section-subtitle">Where can we take you?</div>
            </div>
            <div className="col-12">
              <div className="episode-carousel-wrapper row">
                <div className="episode-carousel-button-next d-flex align-items-center justify-content-center col-1"></div>
                <Swiper
                  effect={'slide'}
                  grabCursor={true}
                  centeredSlides={true}
                  navigation={{
                    prevEl: '.episode-carousel-button-next',
                    nextEl: '.episode-carousel-button-prev',
                  }}
                  className="episode-carousel-container col-10"
                >
                  {episodes.map((episode, index) => (
                    <SwiperSlide key={index}>
                      <div className="row">
                        <a href={episode.videoUrl} target="_blank" rel="noopener noreferrer" className="col-12">
                          <img className="episode-carousel-image" src={episode.image} alt={`Episode ${index + 1}`} />
                        </a>
                        <div className="col-12 text-center mt-3 mt-sm-5 mb-3 m-1">
                          <div className="row">
                            <div className="episode-broker-text-box col-12 col-sm-4">
                              <div className="episode-broker-intro-text">FEATURED BROKER:</div>
                              <div className="episode-broker-name">{episode.name}</div>
                            </div>
                            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
                              <button className="episode-lets-connect" onClick={() => window.location.href = episode.page}>
                                Let's Connect!
                              </button>
                            </div>
                            <div className="episode-social-icon-container col-12  pt-2 pt-sm-0 col-sm-4">
                              <SocialMediaIcons 
                                youtubeLink={episode.yt}
                                facebookLink={episode.fb}
                                instagramLink={episode.insta}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="episode-carousel-button-prev d-flex align-items-center justify-content-center col-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default EpisodeCarouselSection;
  