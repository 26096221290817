import React from 'react';

const SchedulingAppointmentSection = ({ handleProgress }) => {

  const handleAppointmentClick = () => {
    // Assuming the component is complete when the "Yes" button is clicked
    handleProgress('scheduling', true, true);
  };

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">Are you interested in scheduling a listing appointment?</div>
      <button className="scheduling-appointment-button mb-5" onClick={handleAppointmentClick} type="button">
        <div className="lead-gen-investing-interest-text"> Yes </div>
      </button>
    </div>
  );
};

export default SchedulingAppointmentSection;
