import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './DropdownMenu.css';

const DropdownMenu = ({ title, items, actions }) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <div
      className="dropdown"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <button className="dropbtn">{title}</button>
      {isVisible && (
        <div className="dropdown-container p-2 p-sm-4">
          <div className="dropdown-content">
            {items.map((item, index) => (
              <div className="pt-2 pt-sm-1 pb-sm-1 pl-2" key={index}>
                <Link 
                  to="" 
                  onClick={(e) => { e.preventDefault(); actions[index](); }}
                  className={`a dropdown-item-${index}`}>
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;