import React from 'react';
import './Banner.css';

const Banner = ({ height, angle, leftColor, rightColor, text = '', alignment, scrollable, opacity }) => {
  const bannerStyle = {
    height,
    transform: `rotate(${angle})`,
    background: `linear-gradient(90deg, ${leftColor}, ${rightColor})`,
    justifyContent: alignment,
    opacity: scrollable ? opacity : 1
  };

  return (
    <div className={`banner ${scrollable ? 'scroll-banner' : ''}`} style={bannerStyle}>
      <p className={`m-2 m-md-1 m-lg-1 m-xl-2 banner-text ${scrollable ? 'scroll-banner-text' : ''}`}>{`${text} `.repeat(100)}</p>
    </div>
  );
};

export default Banner;