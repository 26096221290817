import LeadGenerationForm from "../../LeadGenerationForm/LeadGenerationForm";
import './LeadGeneration.css'

const LeadGeneration = ({ userInterest, broker, nbhood1, nbhood2, nbhood3 }) => {
    return (
        <div className="lead-gen-section">
            <LeadGenerationForm
                userInterest={userInterest}
                broker={broker}
                nbhood1={nbhood1}
                nbhood2={nbhood2}
                nbhood3={nbhood3}
            />
        </div>
    );
}

export default LeadGeneration;