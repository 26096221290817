const SellingProperty = ({ handleProgress }) => {

  const handleSellProperty = (e) => {
    const isCompleted = e.target.value !== '';
    handleProgress('sellingProperty', isCompleted, e.target.value);
  };

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">Do you need to sell a property?</div>
      <div className="mb-5">
        <select onChange={handleSellProperty}>
          <option value="">Select an answer</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </div>
  );
};

export default SellingProperty;
