import React from 'react';
import './HeroSection.css';
import blueprintBackground from '../../../images/blueprint_background.jpg'
import mountainsBackground from '../../../images/mountains_background.png'
import groupShotNoVignette from '../../../images/group_shot.png'
import Banner from './Banner';

const HeroSection = () => {

  return (
    <div className="hero-section-container">
      <div className="background-images">
        <img className="blueprint-background" src={blueprintBackground} alt="Blueprint Background" />
        <img className="mountains-background" src={mountainsBackground} alt="Mountains Background" />
      </div>
      <div className="hero-section-content">
        <div className="hero-section-text-container">
          <div className="hero-section-text">
            <h1 className="hero-section-title">
              <span>THE</span> 
              <br /> 
              <strong>MASTER</strong><strong>PLAN</strong><em>TV</em>
            </h1>
          </div>
        </div>
        <div className="group-shot-container">
          <img className="group-shot-image" src={groupShotNoVignette} alt="Group Shot" />
        </div>
      </div>

      <Banner 
        height="5%"
        angle="-5deg"
        leftColor="#c1ff3d"
        rightColor="#FF6600"
        text="TOUR. TASTE. EXPLORE."
        alignment="flex-start"
      />
      <div className="vignette-overlay"></div>
    </div>
  );
};

export default HeroSection;
