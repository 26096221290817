export const isFieldComplete = (value) => {
  if (typeof value === 'string') {
    // Check if it's a non-empty string
    return value.trim() !== '';
  } else if (typeof value === 'object' && value !== null) {
    // Check if it's a non-null object (e.g., for objects representing form data)
    return Object.values(value).every((field) => isFieldComplete(field));
  }
  // For other types (including null and undefined), consider them incomplete
  return false;
};

export const calculateProgress = (fields, totalSections) => {
  const completedFields = Object.values(fields).filter((field) => field.isComplete).length;
  return (completedFields / totalSections) * 100;
};
