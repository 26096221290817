const TimeFrameSection = ({ handleProgress }) => {
  const handleTimeFrame = (e) => {
    const isCompleted = e.target.value !== '';
    handleProgress('timeframe', isCompleted, e.target.value);
  };

  return (
    <div>
      <div className="lead-gen-title mt-lg-5 mb-lg-5">What is your desired timeframe to buy?</div>
      <div className="mb-5">
        <select onChange={handleTimeFrame}>
          <option value="">Select a timeframe</option>
          <option value="1 Month">1 Month</option>
          <option value="3 Months">3 Months</option>
          <option value="6 Months">6 Months</option>
          <option value="12 Months">12 Months</option>
        </select>
      </div>
    </div>
  );
};

export default TimeFrameSection;
