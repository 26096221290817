import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from 'swiper/core';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';

import './VideoCarousel.css';

// Enable modules required
SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const VideoCarousel = ({ videoList }) => {
  const slidesPerView = window.innerWidth < 1350 ? 1 : 3;

  return (
    <div className="carousel-wrapper">
      <Swiper
        effect={'slide'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={slidesPerView} 
        pagination={{ clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }} // specify the class name of the next and prev buttons
        className="swiper_container"
      >
        {videoList.map((video, index) => (
          <SwiperSlide key={index}>
            <video className="video" src={video} controls={true} autoPlay={true} loop={true} muted={true} />
          </SwiperSlide>
        ))}
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
      </Swiper>
    </div>
  );
};

export default VideoCarousel;
